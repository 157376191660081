import cardCurve2 from "../../images/card-curve-2.svg";

function BuyCard({ image, logo, btnText, func, isButton }) {
  return (
    <div className="relative sm:w-[33rem]">
      <img
        src={cardCurve2}
        alt="curve"
        className="absolute right-[-1.5px]  lg:top-[-1.3px] md:top-[-1.3px] sm:top-[-1.3px] top-[-1.3px]  w-[7rem] z-10 "
      />
      {isButton&&<div>
        <button
          onClick={() => func()}
          className="absolute z-20 left-0 right-0 bottom-0 mt-4 mb-8 mx-5  rounded-[1.2rem] font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-4 px-5 "
        >
          {" "}
          {btnText}
        </button>
      </div>}
      <div className=" min-h-[25rem] relative border-[2px] border-[#0851DF] rounded-[3rem]">
        <img
          src={image}
          alt="cardimage"
          className="object-cover object-center  min-h-[25rem] rounded-[3rem]"
        />

        <div className="absolute top-0 right-0 z-20">
          <div className="w-[3.8rem] h-[3.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
            <img
              src={logo}
              alt="logo"
              className="object-center w-[2.2rem] h-[2.2rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyCard;
