import React from "react";
import logo from "../images/bayra_logo_b.svg";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

function Footer() {
  return (
    <div className="p-8 text-white mt-3 md:w-[100%] bg-[#0851DF] lg:w-[80%] m-auto rounded-t-[3rem] border-2 border-[#424242]">
      <div className="flex flex-col items-center md:items-end gap-14 md:flex-row md:gap-0">
        <div className="sm:w-[18rem] p-7 flex flex-col items-center justify-center bg-[#2E2D2E] border-2 border-[#424242] rounded-3xl">
          <img src={logo} alt="Bayra Logo" className="mb-3" />
          <p className="text-center text-[12px] font-[500]">
            Bayra is a multi-purpose platform revolutionizing the NFT space by
            combining NFTs, DeFi, and gaming in one place. Bayra is home to the
            CrypFennecs NFT project and will also host other NFT projects such
            as Blops and Pixel Heroes.
          </p>
        </div>
        
          <div className="flex items-end justify-center flex-1">
            <div>
              <ul className="flex flex-col gap-6">
                <li>
                  <Link to="/" className="">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/discover" className="">
                    Discover
                  </Link>
                </li>
                <li>
                  <Link to="/marketplace" className="">
                    Marketplace
                  </Link>
                </li>
                <li>
                  <Link to="/gaming" className="">
                    Gaming
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" className="">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col justify-end flex-1">
            <div className="w-min ">
              <h3 className="mb-4 text-center text-[20px] font-[600]">
                Our Social Media
              </h3>
              <div className="flex flex-row flex-wrap items-center justify-center gap-3 mobile:flex-nowrap">
                <div className="p-2 text-[#2E2D2E] bg-white rounded-full w-min">
                  <FaTwitter className="" size={24} />
                </div>
                <div className="p-2 bg-white rounded-full w-min text-[#2E2D2E]">
                  <FaInstagram className="" size={24} />
                </div>
                <div className="p-2 bg-white rounded-full w-min text-[#2E2D2E]">
                  <FaTelegramPlane className="" size={24} />
                </div>
                <div className="p-2 bg-white rounded-full w-min text-[#2E2D2E]">
                  <FaFacebookF className="" size={24} />
                </div>
                <div className="p-2 bg-white rounded-full w-min text-[#2E2D2E]">
                  <FaYoutube className="" size={24} />
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
  );
}

export default Footer;
