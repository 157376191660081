import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import heroBayra from "../../images/hero_bayra.svg";
import heroCypho from "../../images/hero_crypfe.svg";
import heroGame from "../../images/hero_games.svg";

import cardLogo from "../../images/card_logo.svg";
import cardLogo2 from "../../images/card-logo-2.svg";
import cardLogo3 from "../../images/card-logo-3.svg";

import companyLogo1 from "../../images/company-logo-1.svg";
import companyLogo2 from "../../images/company-logo-2.svg";
import companyLogo3 from "../../images/company-logo-3.svg";
import companyLogo4 from "../../images/company-logo-4.svg";
import companyLogo5 from "../../images/company-logo-5.svg";
import companyLogo6 from "../../images/company-logo-6.svg";
import contactusbg from "../../images/contact-us.png";
import ourgamesbg from "../../images/our_games_bg.svg";
import blopsCard from "../../images/our_games_blops_card.svg";
import blopsLogo from "../../images/our_games_blops_logo.svg";
import blopsheroBg from "../../images/our_games-blops_pixel_bg.svg";
import pixelheroCard from "../../images/our_games_pixel_hero_card.png";
import pixelheroLogo from "../../images/our_games_pixel_hero_logo.svg";
import contactCurve from "../../images/contact-us-curve.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import NftCard from "../../components/nft-card";
import CrypUtilityCard from "../../components/cryp-utility-card";

function Home() {
  const [phone, setPhone] = useState("+1 (555) 000-0000");
  return (
    <div>
      {/* hero section */}
      <div>
        <div className="mt-3 md:w-[100%] lg:w-[80%] m-auto px-7 md:h-[80vh]  sm:h-[30rem] h-[40rem]  bg-hero-pattern    bg-top relative z-20 rounded-[3rem] border-2 border-[#424242] bg-no-repeat bg-cover">
          <div className="relative flex justify-center m-0 align-center md:w-100 md:h-75">
            <div className="pt-5 m-auto h-[21rem]  lg:w-[40rem] md:w-[35rem]  lg:mt-0 lg:bg-bottom md:bg-bottom w-[90vw] bg-hero-title bg-contain  bg-center bg-no-repeat relative">
              <p className="absolute   lg:right-[-2rem] md:right-[2rem]   top-1/3 right-0 font-medium  text-[10px] lg:text-[20px] md:text-[18px] text-white text-right">
                Unleash The Power Of NFT GameFi <br></br> Trade, Earn and
                Battle!
              </p>
            </div>
          </div>
          <div className="absolute  md:bottom-[2rem] bottom-[1.5rem] md:ms-[1rem]">
            <p className="text-white md:w-3/6 lg:w-1/2 text-[12px] md:text-[13px] ">
              Bayra is a multi-purpose platform revolutionizing the NFT space by
              combining NFTs, DeFi, and gaming in one place. Bayra is home to
              the CrypFennecs NFT project and will also host other NFT projects
              such as Blops and Pixel Heroes. The platform will offer a wide
              range of features, including a CrypFennecs DAO, staking and
              loaning, NFT marketplace, and gaming platform powered by the
              $BAYRA token.
            </p>
            <button className=" mt-3 rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              {" "}
              Learn More
            </button>
          </div>
          <div className="absolute bottom-[-1.7px] right-[-1.8px] hidden md:flex gap-3 pt-3 pl-3 bg-black rounded-tl-[3rem]   border-t-2 border-l-2 border-[#424242]">
            <img src={heroBayra} alt="heroBayra" />
            <img src={heroCypho} alt="heroCypho" />
            <img src={heroGame} alt="heroGame" />
          </div>
        </div>
        <div className=" mt-3 right-[-1.8px] flex  align-top justify-around md:hidden gap-3 p-3 bg-black rounded-tl-[3rem] rounded-tr-xl rounded-br-xl rounded-bl-xl  border-2 border-[#424242]">
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroBayra} alt="heroBayra" />
          </div>
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroCypho} alt="heroCypho" />
          </div>
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroGame} alt="heroGame" />
          </div>
        </div>
      </div>
      {/* home features bg */}
      <div className="mt-3 bg-home-features-bg bg-no-repeat bg-center bg-cover md:w-[100%] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="flex flex-col justify-around gap-6 py-5 my-6 align-center sm:flex-row ">
          <div className="flex-col flex-wrap justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Play
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
          <div className="flex flex-col justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Trade
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
          <div className="flex flex-col justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Earn
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
        </div>
      </div>
      {/* home bayrameta */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">BayraMeta</h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[16px]">
            BayraMeta World is P2E Metaverse, with the underground FennecVerse
            only for VIP NFT Owners like fennecs and Upper World for HunterX
            collections( Blops) & Proton/ ETH approved collections.. We bring
            exciting games in which you can earn XPR, Bayra whilst existing in
            the ecosystem. Racing, Shooting, Fighting and other multiplayer
            games for our VIP users. Concerts and Brand Advertisements for our
            corporate partners.
          </p>
        </div>
        <div className="flex flex-col justify-center mt-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4">
          {/* card 1 */}
          <div className="flex flex-col justify-between md:w-1/3 sm:w-2/3 w-3/3 sm:m-0 m-4 px-5 py-5 min-h-[25.5rem]  bg-gradient-to-b to-[#2E2D2E] from-[#0851DF] rounded-[3rem] ">
            <div>
              <h3 className="font-[600] text-[23px] text-center pb-3 ">
                BayraMeta Token
              </h3>
              <div>
                <p className="text-[12px] font-[700] text-white">
                  General Description
                </p>
                <p className="text-[12px] font-[500] text-[#D0D0D0]">
                  $Bayra will be released on the basis of Proton platform and
                  fully comply with XPR standard.
                </p>
                <p className="text-[12px] font-[500] text-[#D0D0D0] mt-1">
                  Support of this standard guarantees the compatibility of the
                  token with third-party services (wallets, exchanges, listings,
                  etc.), and provides easy integration. Herewith, the use of
                  BAYRA token is not limited to just the platform ecosystem.
                </p>
                <div className="flex items-center justify-between gap-1 my-3 no-wrap">
                  <div className="">
                    <p className="text-[12px] font-[700] text-white">Name</p>
                    <p className="text-[12px] font-[500] text-[#D0D0D0]">
                      $Bayra
                    </p>
                  </div>
                  <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
                  <div>
                    <p className="text-[12px] font-[700] text-white">Type</p>
                    <p className="text-[12px] font-[500] text-[#D0D0D0]">XPR</p>
                  </div>
                  <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
                  <div>
                    <p className="text-[12px] font-[700] text-white">Symbol</p>
                    <p className="text-[12px] font-[500] text-[#D0D0D0]">
                      BAYRA
                    </p>
                  </div>
                  <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
                  <div>
                    <p className="text-[12px] font-[700] text-white">
                      Plateform
                    </p>
                    <p className="text-[12px] font-[500] text-[#D0D0D0]">
                      Proton
                    </p>
                  </div>
                </div>
                <p className="text-[12px] font-[500] text-white text-center">
                  General Release:{" "}
                  <span className="text-[12px] font-[500] text-[#D0D0D0]">
                    1,000,000,000 BAYRA
                  </span>
                </p>
              </div>
            </div>
            <div className="">
              <button className="text-[12px] font-[500] text-[#D0D0D0] text-center bg-[#BF08DE] w-full py-3 rounded-xl">
                Buy Token
              </button>
            </div>
          </div>
          {/* card 2 */}
          <div className="flex flex-col justify-between md:w-1/3 sm:w-2/3 w-3/3 sm:m-0 m-4 px-5 py-5 min-h-[25.5rem]  bg-gradient-to-b to-[#2E2D2E] from-[#0851DF] rounded-[3rem] ">
            <div>
              <h3 className="font-[600] text-[23px] text-center pb-3 ">
                Our Mission
              </h3>
              <div>
                <p className="text-[12px] font-[500] text-[#D0D0D0]">
                  Our mission is to create a comprehensive and user-friendly
                  ecosystem where NFT enthusiasts, gamers, and investors can
                  come together to explore the potential of NFTs, DeFi, and
                  gaming. We aim to offer a seamless and enjoyable experience
                  that empowers users to shape the future of the platform.
                </p>
              </div>
            </div>
            <div className="">
              <button className="text-[12px] font-[500] text-[#D0D0D0] text-center bg-[#BF08DE] w-full py-3 rounded-xl">
                Read More
              </button>
            </div>
          </div>
          {/* card 3 */}
          <div className="flex flex-col justify-between md:w-1/3 sm:w-2/3 w-3/3 sm:m-0 m-4 px-5 py-5 min-h-[25.5rem]  bg-gradient-to-b to-[#2E2D2E] from-[#0851DF] rounded-[3rem] ">
            <div>
              <h3 className="font-[600] text-[23px] text-center pb-3 ">
                What is BayraMeta?
              </h3>
              <div>
                <p className="text-[12px] font-[500] text-[#D0D0D0]">
                  BayraMeta World is P2E Metaverse, with the underground
                  FennecVerse only for VIP NFT Owners like fennecs and Upper
                  World for HunterX collections( Blops) & Proton/ ETH approved
                  collections.. We bring exciting games in which you can earn
                  XPR, Bayra whilst existing in the ecosystem. Racing, Shooting,
                  Fighting and other multiplayer games for our VIP users.
                  Concerts and Brand Advertisements for our corporate partners.
                </p>
              </div>
            </div>
            <div className="">
              <button className="text-[12px] font-[500] text-[#D0D0D0] text-center bg-[#BF08DE] w-full py-3 rounded-xl">
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* home Crypfennec */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">
            Crypfennec<span className="text-[#F87226]">.</span>
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[16px]">
            CrypFennecs are an innovative collection of NFTs designed to
            captivate collectors with their unique appearances and attributes.
            These digital creatures are intricately crafted, and their
            individual qualities are brought to life in the imaginative world of
            Bayra. CrypFennecs serve as the heart of the platform, ushering in a
            new era of NFT utility and interaction.
          </p>
        </div>
        <div className="flex flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {CrypUtilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
          })}
          {CrypUtilityCard({
            image: require("../../images/crypfennec_utility.jpeg"),
            logo: cardLogo3,
          })}
          {CrypUtilityCard({
            image: require("../../images/crypfennec_dao.jpeg"),
            logo: cardLogo2,
          })}
        </div>
      </div>

      {/* our games */}
      <div
        style={{
          backgroundImage: `url(${ourgamesbg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white"
      >
        <div className="mt-6 mb-5 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">Our Games</h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] sm:text-[16px] text-[13px]">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world. Each NFT is a one-of-a-kind creation,
            verified on the blockchain, and is sure to capture the attention of
            any collector or art enthusiast.
          </p>
        </div>
        {/* for laptops  */}
        <div className="relative m-auto flex items-initials w-[95%]  flex-col">
          <img
            src={blopsheroBg}
            alt="Background"
            className="hidden object-fill mb-5 xl:flex"
          />
          <div className="absolute   w-[55%] h-[95%] md:p-5 p-0 top-0 left-0 xl:flex hidden items-start justify-between flex-col pt-5 pb-5">
            <div className="    w-[78%]  flex flex-col items-center">
              <img
                src={blopsCard}
                alt="Blops Card"
                className="xl:25rem w-[18rem] object-center object-cover"
              />
            </div>

            <div className="xl:w-[90%] md:w-[100%] w-100">
              <h1 className=" text-[60px] font-[700]">Blops</h1>
              <p className=" xl:text-[14px] md:text-[14px]  font-[500] leading-[20px] text-[#C9C9C9]">
                Blops are a unique and quirky collection of NFTs featuring
                bizarre and odd-looking characters. Each Blops NFT has its own
                set of attributes, including Monsterability, Power, Goofiness,
                and Blopiness. The total of all points determines the rank of a
                Blop. There are four ranks available: Blop, Blop Adv, Blop
                Super, and Blop Ultra, with increasing rarity and attributes as
                the rank increases. With a limited supply of 1000, Blops offer
                collectors a chance to own a rare and distinctive piece of
                digital art.
              </p>
              <button className="py-2 px-14 rounded-md  bg-white text-black text-[16px] mt-3">
                Play Now
              </button>
              <img
                src={blopsLogo}
                alt="Blops Logo"
                className="absolute bottom-[.3rem] right-[.5rem] w-[6rem]"
              />
            </div>
          </div>
          <div className="absolute  w-[55%]  h-[100%]  md:p-5 p-0 top-0 right-0 xl:flex hidden items-stretch justify-between flex-col pt-5 pb-5">
            <div className="relative h-[100%] flex items-start gap-3 ">
              <img
                src={pixelheroLogo}
                alt="Pixel Hero Logo"
                className="w-100 h-100 "
              />

              <div className="flex items-stretch h-[100%] justify-between  flex-col">
                <div>
                  <h1 className=" text-[60px] font-[700] text-black">
                    Pixel Hero
                  </h1>
                  <p className="text-[#38383B] text-[14px]  font-[500] leading-[20px]">
                    Pixel Heroes is a card game NFT project built by an early
                    Proton NFT creator. The project has been handed over to
                    CrypFennecs for integration with the Bayra platform. The
                    game features pixelated characters with various rarities,
                    descriptions, attributes (HP, Armor, Strength, and Magic),
                    and special abilities. The Pixel Heroes collection comprises
                    four types of NFTs: 1st Edition, 2nd Edition, Pixel Avatars
                    (PFPs), and Pixel Heroes Variants. The Pixel Heroes project
                    also has its own token ($PIXEL) that is planned to be
                    replaced by the $BAYRA token on the Bayra platform.
                  </p>
                  <button className="py-2 px-14 rounded-md  bg-[#38383B] text-white text-[16px] mt-3">
                    Play Now
                  </button>
                </div>

                <div className="flex flex-col items-center pb-5">
                  <img
                    src={pixelheroCard}
                    alt="Blops Card"
                    className="xl:25rem w-[18rem] object-center object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles and tablets */}
        <div className="xl:hidden flex flex-col items-center pb-10 mt-3 p-3 w-[100%] bg-black md:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
          <img
            src={blopsCard}
            alt="Blops Card"
            className=" object-cover md:w-[20rem] w-100 items-center object-center"
          />
          <div >
            <div className="flex gap-3">
              <img src={blopsLogo} alt="Blops Logo" className="w-[4rem]" />
              <h1 className=" md:text-[60px] text-[40px]  font-[700]">Blops</h1>
            </div>
            <div className="flex flex-col items-center">
            <p className="  xl:text-[14px] md:text-[14px] text-[12px] font-[500] text-justify leading-[20px] text-[#C9C9C9]">
              Blops are a unique and quirky collection of NFTs featuring bizarre
              and odd-looking characters. Each Blops NFT has its own set of
              attributes, including Monsterability, Power, Goofiness, and
              Blopiness. The total of all points determines the rank of a Blop.
              There are four ranks available: Blop, Blop Adv, Blop Super, and
              Blop Ultra, with increasing rarity and attributes as the rank
              increases. With a limited supply of 1000, Blops offer collectors a
              chance to own a rare and distinctive piece of digital art.
            </p>
            <button className="py-2 px-14 rounded-md  bg-white text-black text-[16px] mt-3 m-auto">
              Play Now
            </button>
            </div>
          </div>
        </div>
        <div className="xl:hidden flex flex-col text-black items-center pb-10 mt-3 mb-4 p-3 w-[100%] bg-[#A8A6A1] md:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
          <img
            src={pixelheroCard}
            alt="pixelheroCard"
            className=" object-cover md:w-[20rem] w-100 items-center object-center"
          />
          <div>
            <div className="flex gap-3">
              <img
                src={pixelheroLogo}
                alt="pixelheroLogo"
                className="w-[4rem]"
              />
              <h1 className=" md:text-[60px] text-[40px] font-[700]">
                Pixel Hero
              </h1>
            </div>
            <div className="flex flex-col items-center">
            <p className=" xl:text-[14px] md:text-[14px] text-[12px] text-justify  font-[500] leading-[20px] text-[#38383B]">
              Pixel Heroes is a card game NFT project built by an early Proton
              NFT creator. The project has been handed over to CrypFennecs for
              integration with the Bayra platform. The game features pixelated
              characters with various rarities, descriptions, attributes (HP,
              Armor, Strength, and Magic), and special abilities. The Pixel
              Heroes collection comprises four types of NFTs: 1st Edition, 2nd
              Edition, Pixel Avatars (PFPs), and Pixel Heroes Variants. The
              Pixel Heroes project also has its own token ($PIXEL) that is
              planned to be replaced by the $BAYRA token on the Bayra platform.
            </p>
            <button className="py-2 px-14 rounded-md  bg-[#38383B] text-white text-[16px] mt-3">
              Play Now
            </button>
            </div>
          </div>
        </div>
      </div>

      {/* recently added */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Added</h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
          </div>
        </ScrollContainer>
      </div>

      {/* recently auctions */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Auctions</h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 overflow-auto no-scrollbar "
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
          </div>
        </ScrollContainer>
      </div>

      {/* contact us */}
      <div
        className=" relative mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white"
        style={{
          backgroundImage: `linear-gradient(360deg, #2E2D2E -0.01%, rgba(46, 45, 46, 0) 100%),url(${contactusbg}) `,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* image */}
        <img
          src={contactCurve}
          alt="contactus curve"
          className="absolute w-[19rem] top-[-8.4px] right-[-8px] lg:flex hidden"
        />
        {/* text */}
        <h1 className="text-[54px] font-[700] text-[#5A5A5A] absolute top-5 right-0 w-[11rem] lg:flex hidden">
          Get in Touch
        </h1>
        <div className="flex flex-col-reverse items-end justify-around mb-10 md:flex-row">
          <div className="md:w-[40%] w-[90%] md:m-0 m-auto">
            <h1 className="sm:text-[54px] text-[40px] sm:mt-0 mt-10 font-[700] text-white pt-2  ">
              Contact Us
            </h1>
            <form action="#" class="space-y-4">
              {/* fist name and last name */}
              <div className="flex mt-5 w-[100%] gap-3 sm:flex-row flex-col">
                {/* first name */}
                <div className="flex-1">
                  <label
                    for="fname"
                    class="block mb-2 text-sm font-[700] text-[14px]"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="fname"
                    styles={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    className=" bg-black w-[100%] border-white border-[3px] text-white text-sm rounded-lg p-2.5 placeholder:text-white"
                    placeholder="First name"
                    required
                  />
                </div>
                {/* last name */}
                <div className="flex-1">
                  <label
                    for="lname"
                    class="block mb-2 text-sm font-[700] text-[14px]"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lname"
                    styles={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    className=" bg-black w-[100%] border-white border-[3px] text-white text-sm rounded-lg   p-2.5 placeholder:text-white"
                    placeholder="Last name"
                    required
                  />
                </div>
              </div>
              {/* email */}
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-[700] text-[14px]"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  styles={{
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  }}
                  className=" bg-black  border-white border-[3px] text-white text-sm rounded-lg  w-full p-2.5 placeholder:text-white"
                  placeholder="you@company.com"
                  required
                />
              </div>
              {/* phone no */}
              <div>
                <label
                  for="phone no"
                  class="block mb-2 text-sm font-[700] text-[14px]"
                >
                  Phone No
                </label>
                <PhoneInput
                  country={"us"}
                  className="bg-black  border-white border-[3px] text-white rounded-lg  "
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
              {/* message */}
              <div>
                <label
                  for="message"
                  class="block mb-2 text-sm font-[700] text-[14px]"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  styles={{
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  }}
                  className=" bg-black  border-white border-[3px] h-[9rem] text-white text-sm rounded-lg  w-full p-2.5 placeholder:text-white"
                  required
                />
              </div>
              {/* privacy policy */}
              <div class="flex flex-row items-center space-x-2">
                <input
                  type="checkbox"
                  id="checboxing"
                  styles={{
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  }}
                  className=" bg-black  border-white border-[3px] h-[1rem] text-white text-sm rounded-lg  p-2.5 placeholder:text-white"
                  required
                />
                <p className="text-[16px] font-[500]">
                  You agree to our friendly{" "}
                  <Link href="/" style={{ textDecoration: "underline" }}>
                    privacy policy
                  </Link>
                  .
                </p>
              </div>
              <button
                type="submit"
                className="py-3 px-5 w-[100%] text-base font-medium text-center text-white  bg-[#BF08DE] "
              >
                Send message
              </button>
            </form>
          </div>
          <div className="md:w-[40%] w-[90%]  md:m-0 m-auto flex flex-col items-start justify-end ">
            <h1 className="sm:text-[54px] text-[40px] sm:mt-0 mt-10 font-[700] text-white  lg:hidden flex">
              Get In Touch
            </h1>
            <p className="text-[16px] font-[400] leading-[20px]  text-[#E6E6E6]">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
        </div>
      </div>

      {/* company section */}
      <div className="mt-3 md:w-[100%] bg-black lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#0851DF] text-white">
        <div className="flex flex-col items-center pt-10 pb-10">
          <h1 className=" font-[700] text-[34px] my-10 text-center">
            A WORLDWIDE REVOLUTION IN GAMING
          </h1>
          <div className="flex flex-wrap items-center justify-center gap-10 mb-7">
            <img src={companyLogo1} alt="Company logo 1"></img>
            <img src={companyLogo2} alt="Company logo 2"></img>
            <img src={companyLogo3} alt="Company logo 3"></img>
            <img src={companyLogo4} alt="Company logo 4"></img>
            <img src={companyLogo5} alt="Company logo 5"></img>
            <img src={companyLogo6} alt="Company logo 6"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;



