import React from "react";
import heroPattern from "../images/hero_pattern.png";
import cardCurve2 from "../images/card-curve-2.svg";
import cardLogo from "../images/card_logo.svg";



function NftCard({ isSubmit }) {
    return (
      <div className="mobile:w-[19rem] w-[94vw]">
        <div className="relative  mobile:w-[19rem] w-[94vw] min-h-[22.6rem] rounded-[3rem] bg-[#0851DF] cursor-pointer">
          <img
            src={cardCurve2}
            alt="cardimage"
            className="absolute right-[-1.4px]  top-[-1.5px]  w-[7rem] z-10 "
          />
          {isSubmit && (
            <div className="absolute top-[3rem] left-0 bg-[#0851DF] z-30 px-5 py-2 rounded-tr-full rounded-br-full  ">
              <h2 className="text-[20px] font-[700]">20 Bids</h2>
            </div>
          )}
  
          <div className="relative border-[2px]  border-[#0851DF] rounded-[3rem]">
            <img
              src={heroPattern}
              alt="cardimage"
              className="object-cover  rounded-[3rem] h-[16.8rem]"
            />
  
            <div className="absolute top-0 right-0 z-20">
              <div className="w-[3.8rem] h-[3.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
                <img
                  src={cardLogo}
                  alt="cardimage"
                  className="object-center w-[2.2rem] h-[2.2rem]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-around text-white">
            <div className="">
              <h6 className="text-[16px] font-[700] text-center mt-3 leading-[20px]">
                Lorem Ipsum
              </h6>
              <p className="text-[12px] font-[500] text-center leading-[15px] ">
                Owner: Lorem Ipsum
              </p>
            </div>
            {isSubmit && (
              <h1 className="text-[33px] font-[700] p-0 m-0">10:12:13</h1>
            )}
            {!isSubmit && <div className="my-2"></div>}
            <p className="text-[12px] font-[500] text-center leading-[15px] mt-[4px] mb-[8px]">
              850000 XUSD
            </p>
          </div>
        </div>
        {isSubmit && (
          <div className="mt-4 ">
            <button className="bg-[#BF08DE] w-full py-4 rounded-full text-[16px] font-[700] leading-[20px]">
              Make an offer
            </button>
          </div>
        )}
      </div>
    );
  }

  
export default NftCard;