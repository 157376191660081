import React from "react";

function SpecialCard({date,title,desc}) {
  return (
    <div className="px-4 mobile:px-0">
      <div className="relative flex items-end justify-center px-2 py-3 h-[10rem]  rounded-[1.6rem] bg-[#0851DF] mobile:w-[12.4rem] w-[100%]  ">
        <div className="absolute w-[98%] mb-3 items-start flex justify-center top-[-0.6rem] bg-white text-black py-5 rounded-[1.6rem]">
          <h1 className="text-[16px] font-[600]">{date}</h1>
        </div>
        <div className="flex flex-col items-center gap-2 mt-3 mb-2 text-white justify-normal">
          <h3 className="text-[14px] font-[600] text-center">{title}</h3>
          <p className="text-[11px] font-[400] text-center">{desc}</p>
        </div>
      </div>
    </div>
  );
}

export default SpecialCard;
