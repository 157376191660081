import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Navbar from "./components/navbar.js";
import Footer from "./components/footer";
import Crypfennec from "./pages/crypfennce";
import Story from "./pages/story";
import Chapters from "./pages/chapters";
import BayraMeta from "./pages/bayra_meta";
import BayraGamming from "./pages/bayra_gaming";
import CardPixelHero from "./pages/card_pixel_hero";
import CardPixelBlops from "./pages/card_pixel_blops";
import MarketPlace from "./pages/market_place";
import MarketplaceCrypfennec from "./pages/market_place/crypfennec";
import MarketplaceBlops from "./pages/market_place/blops";
import MarketplacePixelHero from "./pages/market_place/pixel_hero";

function App() {
  return (
    <>
      <div className="bg-black font-Montserrat">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/crypfennce" element={<Crypfennec />} />
          <Route path="/story" element={<Story />} />
          <Route path="/chapters" element={<Chapters />} />
          <Route path="/bayrameta" element={<BayraMeta />} />
          <Route path="/bayragaming" element={<BayraGamming />} />
          <Route path="/bayragaming/pixel" element={<CardPixelHero />} />
          <Route path="/bayragaming/blops" element={<CardPixelBlops />} />
          <Route path="/marketplace" element={<MarketPlace />} />
          <Route path="/marketplace/crypfennec" element={<MarketplaceCrypfennec />} />
          <Route path="/marketplace/blops" element={<MarketplaceBlops />} />
          <Route path="/marketplace/pixelhero" element={<MarketplacePixelHero />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
