import React from "react";


function FennceCard({ heroPattern, name, owner, nftCount ,func }) {
  return (
    <div className="w-[10.9rem] ">
      <div
        onClick={() => func()}
        className={`relative w-[10.9rem]  min-h-[12.5rem] rounded-[1.6rem] bg-[#0851DF] cursor-pointer`}
      >
        <div
          className={`relative  border-[2px] border-[#0851DF] rounded-[1.6rem]`}
        >
          <img
            src={heroPattern}
            alt="cardimage"
            className=" object-cover object-right-bottom rounded-[1.6rem] h-[9rem] "
          />
          {/* <div className="absolute top-0 left-0 w-[10.7rem] h-[8.5rem] bg-gradient-to-b rounded-[1.6rem] from-gray-900 via-transparent"></div> */}
        </div>

        <div className="flex flex-col items-center justify-around text-white">
          <div className="">
            <h6 className="text-[16px] font-[700] text-center mt-3 leading-[20px]">
              {name}
            </h6>
            <p className="text-[12px] font-[500] text-center leading-[15px] ">
              Owner: {owner}
            </p>
          </div>
          <p className="text-[12px] font-[500] text-center leading-[15px] mt-[4px] mb-[8px]">
            {nftCount} XUSD
          </p>
        </div>
      </div>
    </div>
  );
}

export default FennceCard;
