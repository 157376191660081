import React from "react";
import CrypUtilityCard from "../../components/cryp-utility-card";
import cardLogo from "../../images/card_logo.svg";
import cardLogo2 from "../../images/card-logo-2.svg";
import cardLogo3 from "../../images/card-logo-3.svg";
import BuyCard from "./buy_card";
import ElementCard from "./element_card";
import StatsCard from "./stats_card";
import AbilityCard from "./ability_card";

function CardPixelHero() {
  return (
    <div>
      {/* pixel hero */}
      <div>
        <div className="mt-3 md:w-[100%] lg:w-[80%] m-auto px-7   bg-[#2E2D2E] text-[#5A5A5A]  bg-top relative z-20 rounded-[3rem] border-2 border-[#424242] bg-no-repeat bg-cover">
          <h1 className=" break-words md:ms-[1rem] mt-10 font-[400] mobile:text-[20px]  text-[16px] font-PvcBanner">
            About Cards
          </h1>
          <h1 className=" md:ms-[1rem]  mt-3 font-PvcBannerUltra text-[20px] mobile:text-[30px] sm:text-[35px] lg:text-[60px] md:text-[44px] text-white break-words">
            Pixel Hero
          </h1>

          <div className=" mb-4 mt-4 md:ms-[1rem] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <button className=" mt-4 mb-4  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              {" "}
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* cards types */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            CARD TYPES
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            Other online collectible card games sacrifice the real world value
            of physical cards for the sake of convenience. We see a better way.
          </p>
        </div>
        <div className="flex  flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {BuyCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            btnText: "Buy Card",
            func: () => {},
            isButton: true,
          })}
          {BuyCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            btnText: "Buy Card",
            func: () => {},
            isButton: true,
          })}
        </div>
      </div>

      {/* card elements */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            Card Elements
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            Other online collectible card games sacrifice the real world value
            of physical cards for the sake of convenience. We see a better way.
          </p>
        </div>
        <div className="flex basis-2/4 justify-center mt-5 mb-5 items-center flex-wrap flex-row md:w-[80%] w-[100%] m-auto gap-2 md:p-0 p-4">
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {ElementCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
        </div>
        <div className="mt-6 mb-8 sm:ms-14 ms-8">
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            Each of these primal forces is championed by distinct factions known
            as Elements.A Summoner uses magic to call upon the various races,
            habitats, and creatures that exist within the same Element. For
            example, a Summoner of the Earth Element will be able to call forth
            monsters that live in the mountains and forests.
          </p>
        </div>
      </div>

      {/* card basics */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            Cards Basics
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            Each battle has a different combination of combat rules, total Mana
            cap, and playable Elements! Strategy is critical in creating the
            best team for the given situation.
          </p>
        </div>
        <div className="flex  flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {BuyCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
        </div>
        <div className="flex flex-col gap-4 mx-5 mb-5">
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
          <div className="w-full h-40 bg-gray-200 sm:h-20 rounded-2xl">
            <div className="w-full h-20 bg-gray-700 sm:w-32 rounded-2xl"></div>
          </div>
        </div>
      </div>

      {/* the stats */}
      <div className="mt-3 md:w-[100%]  lg:w-[80%] m-auto rounded-[3rem]  text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            The Stats
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            As mentioned previously, STATS are the core attributes of the
            character. If you ever want to review how a character’s STATS will
            change as it levels up, simply head over to Your Collection. Click
            on the character, then select the second tab labeled “STATS”. This
            will display a chart that shows you all the STAT increases and
            Ability unlocks for each level. Okay, let’s de-mystify what each
            STAT means. It’s actually pretty simple and straightforward.
          </p>
        </div>
        <div className="flex  flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
        </div>
      </div>

      {/* other stats */}
      <div className="mt-3 md:w-[100%]  lg:w-[80%] m-auto rounded-[3rem]  text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            Other Stats
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            As mentioned previously, STATS are the core attributes of the
            character. If you ever want to review how a character’s STATS will
            change as it levels up, simply head over to Your Collection. Click
            on the character, then select the second tab labeled “STATS”. This
            will display a chart that shows you all the STAT increases and
            Ability unlocks for each level. Okay, let’s de-mystify what each
            STAT means. It’s actually pretty simple and straightforward.
          </p>
        </div>
        <div className="flex  flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
          {StatsCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo,
            isButton: false,
          })}
        </div>
      </div>

      {/* abilities */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 mb-14 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            Abilities
          </h1>
        </div>
        <div className="flex basis-2/4 justify-center mt-5 mb-5 items-center flex-wrap flex-row md:w-[80%] w-[100%] m-auto gap-2 md:p-0 p-4">
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
          {AbilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
          })}
        </div>
      </div>

      {/* the battlefield */}
      <div className="mt-3 pb-8 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            THE BATTLEFIELD
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            To be successful in battle you have to understand the terrain. The
            battlefield consists of two rows and six positions. The strength of
            your forces depends on how you can utilize these positions and
            anticipate your opponent.
          </p>
        </div>
      </div>

      {/* perparing for battle */}
      <div className="mt-3 pb-8 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
            PREPARING FOR BATTLE
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px]">
            To be successful in battle you have to understand the terrain. The
            battlefield consists of two rows and six positions. The strength of
            your forces depends on how you can utilize these positions and
            anticipate your opponent.<br></br>In this example, there are no
            changes to the standard combat rules, your team is limited to a
            total of 25 Mana, and you may only use characters from the Fire,
            Earth, or Death elements. Armed with this knowledge, you are now
            ready to create your team
          </p>
          <div className="h-32 w-[95%] bg-gray-300 my-5 rounded-[1.6rem]"></div>

          <div className="mb-3">
            <h1 className="text-white font-[600] m-0 p-0 text-[16px]">
              STEP 1:
            </h1>
            <h1 className="text-white font-[600] m-0 p-0 text-[16px]">
              SUMMONER
            </h1>
            <p className="text-white w-[90%] m-0 p-0 leading-[20px] font-[500] text-[12px]">
              First, you’ll need to select a Summoner. The team building screen
              will display all the Summoners in your Collection. Your choice of
              Summoner will determine which Monsters you can use, so choose
              wisely.
            </p>
          </div>
          <div className="mb-3">
            <h1 className="text-white font-[600] text-[16px] m-0 p-0">NOTE:</h1>
            <p className="text-white w-[90%] leading-[20px] m-0 p-0 font-[500] text-[12px]">
              The level of your Summoner determines the level of the Monsters
              that can be added to the team. To see the capabilities of your
              Summoner, click on the Summoner card from your Collection screen,
              then select the STATS tab.
            </p>
          </div>
          <div className="mb-3">
            <h1 className="text-white font-[600] m-0 p-0 text-[16px]">
              STEP 2:
            </h1>
            <h1 className="text-white font-[600] m-0 p-0 text-[16px]">
              MONSTERS
            </h1>
            <p className="text-white w-[90%] m-0 p-0 leading-[20px] font-[500] text-[12px]">
              Now that you’ve chosen a Summoner, the Monsters from the
              Summoner’s element will be made available for selection. You may
              also choose any Neutral monsters, which are monsters that hold no
              particular allegiance.
            </p>
            <h1 className="text-white w-[90%] font-[600] m-0 p-0 text-[16px]">
              You’ve already spent a few Mana when you selected your Summoner.
              Keep an eye on the Mana counter. It will indicate how close you
              are to reaching the limit.
            </h1>
            <h1 className="text-white font-[600] m-0 p-0 text-[16px]">
              POSITIONING
            </h1>
            <p className="text-white w-[90%] m-0 p-0 leading-[20px] font-[500] text-[12px]">
              The first Monster on the list will be in the front row once combat
              begins. Remember the three forms of Attack? Melee and Magic
              attackers may hit from the front row, however Ranged attackers
              require distance from their foe. Keep this in mind when you
              organize who goes where.<br></br> When the Monster in the front
              row (tank position) dies, the next Monster moves up to take the
              first spot. This continues until there are no Monsters left. To
              move a Monster to a different slot, simply drag a Monster to the
              slot you wish it to occupy.
            </p>
          </div>

          <div className="h-32 w-[95%] bg-gray-300 my-5 rounded-[1.6rem]"></div>
        </div>
      </div>

      {/* combat */}
      <div>
        <div className="mt-3 md:w-[100%] lg:w-[80%] m-auto px-7   bg-[#2E2D2E] text-[#5A5A5A]  bg-top relative z-20 rounded-[3rem] border-2 border-[#424242] bg-no-repeat bg-cover">
          <div className=" mb-4 mt-5 md:ms-[1rem] ">
            <h1 className="text-[#7B7B7B]  font-[700] text-[34px] mb-2">
              COMBAT
            </h1>
            <p className=" w-[95%] leading-[20px] font-[500] text-[12px] mt-4">
              It’s the moment of Truth. Your team has charged into battle.
              Hopefully you gave them an inspirational pep talk. <br></br> The
              battleground is split in half, with your team on one side and the
              enemy on the other. At the halfway point, the front-row Monsters
              from each team face off against each other. The Monsters with the
              highest Speed will take their turns first. If two monsters' speeds
              are equal, then attack type is used to break the tie: first magic,
              then ranged, and finally melee. If the attack types are the same,
              then a random order is chosen.<br></br> As the battle unfolds, you
              may wish to take note of what works and what doesn’t. After
              participating in several fights, you will become more familiar
              with what combinations of Monsters work well for different types
              of battles and in what positions they are most effective.{" "}
              <br></br>So what are you waiting for?
            </p>

            <button className=" mt-4 mb-4  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              {" "}
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardPixelHero;
