import cardCurve2 from "../../images/card-curve-2.svg";

function ElementCard({ image }) {
  return (
    <div className="relative w-[9rem]">
      <img
        src={cardCurve2}
        alt="curve"
        className="absolute right-[-0.5px]  top-[-1.2px]  w-[3.5rem] z-10 "
      />
     
      <div className=" min-h-[7rem] relative border-[1.4px] border-[#0851DF] rounded-[1.5rem]">
        <img
          src={image}
          alt="cardimage"
          className="object-cover object-center  min-h-[7rem] rounded-[1.5rem]"
        />

        <div className="absolute top-0 right-0 z-20">
          <div className="w-[1.8rem] h-[1.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
            {/* <img
              src={logo}
              alt="logo"
              className="object-center w-[2.2rem] h-[2.2rem]"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElementCard;
