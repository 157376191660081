import cardCurve2 from "../images/card-curve-2.svg";

function CrypUtilityCard({ image, logo }) {
    return (
      <div className="relative">
        <img
          src={cardCurve2}
          alt="curve"
          className="absolute right-[-1.5px]  lg:top-[-1.3px] md:top-[-1.3px] sm:top-[-1.3px] top-[-1.3px]  w-[7rem] z-10 "
        />
  
        <div className=" min-h-[25rem] relative border-[2px] border-[#0851DF] rounded-[3rem]">
          <img
            src={image}
            alt="cardimage"
            className="object-cover object-center  min-h-[25rem] rounded-[3rem]"
          />
  
          <div className="absolute top-0 right-0 z-20">
            <div className="w-[3.8rem] h-[3.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
              <img
                src={logo}
                alt="logo"
                className="object-center w-[2.2rem] h-[2.2rem]"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }


  export default CrypUtilityCard;