import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ChapterCard from "../../components/chapter-card";

function Story() {
  const [anatomy, setAnatomy] = useState(2);
  return (
    <div>
      {/* the story */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[400] text-[20px] text-white  font-PvcBanner">
            The
          </h1>
          <h1 className=" font-[700] sm:text-[48px] mobile:text-[38px] text-[28px] text-white mb-2 font-PvcBannerUltra">
            Story
          </h1>
          <p className="text-white w-[90%] leading-[20px] font-[500] text-[12px] mt-2">
            For years, the fox fennecs faced extinction due to the selfish
            actions of human’s.Seeing the state of the foxes, the Leader Fennec
            later to be called “The Savior” ☀️ decided it was time to act or
            inevitable doom was imminent. He gathered all his Commanders, the
            chiefs, and his scientists. A meeting was held and the outcome was
            to change the destiny of Foxes forever.The scientists created ”The
            Formula” that can transform fennec's to super Foxes. Contaminating
            the water supply with the formula, the fennec’s transformed, and a
            new era begun with a much more superior civilization of fennec’s.🌤
            Welcome to the universe of CrypFennec’s, a selection of limited 1
            Edition Fennec’s living in the Saharan desert of Algeria.🏜 They live
            under the rule of the “The Savior”.
          </p>
          <p className="text-white w-[90%] leading-[20px] font-[500]  text-[12px] mt-3 mb-6">
            This universe contains over 500-TBD Foxes:
            <ol className="list-decimal ps-6">
              <li>Legendary Leader Fox,</li>
              <li>Rare Chiefs</li>
              <li>Uncommon Commanders & Scientists 👨‍🔬 ,</li>
              <li>Common Civilian Foxes & Soldier Foxes, & </li>
              <li>Common Specials🧛‍️( Vampires, Zombies et</li>
            </ol>
          </p>
          
        </div>
      </div>

      {/* the chapters */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            The Chapters
          </h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {ChapterCard({
              heroPattern: require("../../images/chapter1.jpg"),
              chapter: "Chapter 1",
              title: "Origins",
              isSelected:true,
              func:()=>{}
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter2.jpg"),
              chapter: "Chapter 2",
              title: "The Rise of the Fennecs",
              isSelected:true,
              func:()=>{}

              
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter3.png"),
              chapter: "Chapter 3",
              title: "The Fennce Wars",
              isSelected:true,
              func:()=>{}

            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter4.png"),
              chapter: "Chapter 4",
              title: "Apocalyptics",
              isSelected:true,
              func:()=>{}

            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter5.jpg"),
              chapter: "Chapter 5",
              title: "Civilization Peak",
              isSelected:true,
              func:()=>{}

            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter6.png"),
              chapter: "Chapter 6",
              title: "Galactic",
              isSelected:true,
              func:()=>{}

            })}
          </div>
        </ScrollContainer>
      </div>

      {/* anatomy */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Anatomy
          </h1>
          <div className="flex flex-wrap items-center justify-center gap-3">
            <div onClick={()=>setAnatomy(0)} className={`lg:px-[5rem] md:px-[3.5rem] px-[2.5rem] lg:py-4 md:py-3 py-2 rounded-full cursor-pointer ${anatomy === 0 ? 'bg-[#BF08DE] border-2 border-[#BF08DE]' : 'border-2 border-[#424242]'} `}>
              <h2 className="text-white lg:text-[20px] md:text-[17px] text-[14px] font-[600]">Fennec 1</h2>
            </div>
            <div onClick={()=>setAnatomy(1)} className={`lg:px-[5rem] md:px-[3.5rem] px-[2.5rem] lg:py-4 md:py-3 py-2 rounded-full cursor-pointer ${anatomy === 1 ? 'bg-[#BF08DE] border-2 border-[#BF08DE]' : 'border-2 border-[#424242]'} `}>
              <h2 className="text-white lg:text-[20px] md:text-[17px] text-[14px] font-[600]">Fennec 2</h2>
            </div>
            <div onClick={()=>setAnatomy(2)} className={`lg:px-[6rem] md:px-[4.5rem] px-[3.5rem] lg:py-4 md:py-3 py-2 rounded-full cursor-pointer ${anatomy === 2 ? 'bg-[#BF08DE] border-2 border-[#BF08DE]' : 'border-2 border-[#424242]'} `}>
              <h2 className="text-white lg:text-[20px]  md:text-[17px] text-[14px] font-[600]">Bee</h2>
            </div>
          </div>
          <div className="flex items-center justify-center my-10 ">
            { anatomy === 0 && <img className="" src={require("../../images/anatomy-fennce1.png")} alt="Anatomy Fennce 1" /> }
            { anatomy === 1 && <img src={require("../../images/anatomy-fennce2.png")} alt="Anatomy Fennce 2" /> }
            { anatomy === 2 && <img className="p-2 lg:p-0" src={require("../../images/anatomy-bee.png")} alt="Anatomy Bee" /> }
          </div>
          <p className="text-[#7B7B7B] w-[95%] leading-[20px] font-[400] text-[14px] mt-2 mb-2">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world. Each NFT is a one-of-a-kind creation,
            verified on the blockchain, and is sure to capture the attention of
            any collector or art enthusiast.
          </p>
        </div>
      </div>

      {/* the interactive map */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            The Interactive Map
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Story;
