const chapterData = [
  {
    chapter: "Chapter-01",
    title: "Origin",
    image: require("../../images/chapter1.jpg"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
  {
    chapter: "Chapter-02",
    title: "Rise of The Fennec",
    image: require("../../images/chapter2.jpg"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
  {
    chapter: "Chapter-03",
    title: "The Fennec Wars",
    image: require("../../images/chapter3.png"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
  {
    chapter: "Chapter-04",
    title: "Apocalyptic",
    image: require("../../images/chapter4.png"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
  {
    chapter: "Chapter-05",
    title: "Civilization peak",
    image: require("../../images/chapter5.jpg"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
  {
    chapter: "Chapter-06",
    title: "Galactic",
    image: require("../../images/chapter6.png"),
    description: [
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world. Each NFT is a one-of-a-kind creation, verified on the blockchain, and is sure to capture the attention of any collector or art enthusiast.`,
      `Step into a world where art meets blockchain technology. Our exclusive collection of non-fungible tokens (NFTs) showcases some of the most unique and stunning works of digital art from talented artists around the world.`,
    ],
  },
];

module.exports = chapterData;
