import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import FennceCard from "../../components/fennce-card";
import SpecialCard from "./special_card";
import arrowLg from "../../images/arrow_lg.svg";
import arrowSm from "../../images/arrow_sm.svg";
import arrowDown from "../../images/arrow_down_lg.svg";
import arrowDownSm from "../../images/arrow_down_sm.svg";
import arrowDownMd from "../../images/arrow-down-md.svg";

function BayraMeta() {
  return (
    <div>
      {/* bayra meta */}
      <div>
        <div className="mt-3 md:w-[100%] lg:w-[80%] m-auto px-7   bg-[#2E2D2E] text-[#5A5A5A]  bg-top relative z-20 rounded-[3rem] border-2 border-[#424242] bg-no-repeat bg-cover">
          <h1 className=" break-words md:ms-[1rem] mt-10 font-[400] mobile:text-[20px]  text-[16px] font-PvcBanner">
            Crypfennec Gaming Blockchain
          </h1>
          <h1 className=" md:ms-[1rem]  mt-3 font-PvcBannerUltra text-[20px] mobile:text-[30px] sm:text-[35px] lg:text-[60px] md:text-[44px] text-white break-words">
            BayraMeta
          </h1>

          <div className=" mb-4 mt-4 md:ms-[1rem] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* bayra meta token */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            BayraMeta Token
          </h1>
          <div className="mt-4 mb-4 text-[#7B7B7B]">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
          <div className="md:w-[55%] sm:w-[70%] flex items-center justify-between gap-1 my-3 mobile:flex-nowrap flex-wrap">
            <div className="">
              <p className="text-[12px] font-[700] text-[#646464]">Name</p>
              <p className="text-[12px] font-[500] text-[#898989]">$Bayra</p>
            </div>
            <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
            <div>
              <p className="text-[12px] font-[700] text-[#646464]">Type</p>
              <p className="text-[12px] font-[500] text-[#898989]">XPR</p>
            </div>
            <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
            <div>
              <p className="text-[12px] font-[700] text-[#646464]">Symbol</p>
              <p className="text-[12px] font-[500] text-[#898989]">BAYRA</p>
            </div>
            <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
            <div>
              <p className="text-[12px] font-[700] text-[#646464]">Plateform</p>
              <p className="text-[12px] font-[500] text-[#898989]">Proton</p>
            </div>
            <div className="w-[1px] h-5 bg-[#D0D0D0] rounded-full m-0 p-0"></div>
            <div>
              <p className="text-[12px] font-[700] text-[#646464]">
                General Release
              </p>
              <p className="text-[12px] font-[500] text-[#898989]">
                000,000,000 BAYRA
              </p>
            </div>
          </div>
          <button className="w-[95%] py-3 mt-3 w-100 rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white  px-5 ">
            Buy Token
          </button>
        </div>
      </div>

      {/* our mission */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Our Mission
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* what is bayra? */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            What is Bayra?
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* token distribution */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Token Distribution
          </h1>
          <div className="flex items-end justify-end ">

            <div className="bg-gray-500 rounded-full w-[100%] h-[300px] sm:h-[21rem] sm:w-[21rem] m-5 me-10">
                
            </div>
          </div>
          
        </div>
      </div>

      {/* white Papper */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            What is Bayra?
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
          <button className=" mt-3 rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
            {" "}
            Learn More
          </button>
        </div>
      </div>

      {/* our special way */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Our Special way
          </h1>
        </div>

        <div className="my-14">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col w-full md:flex-row mobile:w-max">
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
              <div className="flex items-center justify-center mb-4 md:mb-0">
                <img
                  src={arrowLg}
                  alt="arrow next"
                  className="hidden m-auto md:flex"
                />
                <img
                  src={arrowDownMd}
                  alt="arrow next"
                  className="flex m-auto md:hidden"
                />
              </div>
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
              <div className="flex items-center justify-center mb-4 md:mb-0">
                <img
                  src={arrowLg}
                  alt="arrow next"
                  className="hidden m-auto md:flex"
                />
                <img
                  src={arrowDownMd}
                  alt="arrow next"
                  className="flex m-auto md:hidden"
                />
              </div>
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
            </div>
            <div className="mb-4">
              <img
                src={arrowDown}
                alt="arrow down"
                className="w-[97%] m-auto md:flex hidden"
              />
              <img
                src={arrowDownMd}
                alt="arrow down"
                className="w-[90%] m-auto md:hidden flex"
              />
            </div>

            <div className="flex flex-col w-full md:gap-2 md:flex-row mobile:w-max">
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
              <div className="flex items-center justify-center mb-4 md:mb-0">
                <img
                  src={arrowLg}
                  alt="arrow next"
                  className="hidden m-auto md:flex"
                />
                <img
                  src={arrowDownMd}
                  alt="arrow next"
                  className="flex m-auto md:hidden"
                />
              </div>
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
              <div className="flex items-center justify-center mb-4 md:mb-0">
                <img
                  src={arrowLg}
                  alt="arrow next"
                  className="hidden m-auto md:flex"
                />
                <img
                  src={arrowDownMd}
                  alt="arrow next"
                  className="flex m-auto md:hidden"
                />
              </div>
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
            </div>
            <div className="flex items-end justify-end mb-4">
              <img
                src={arrowDownSm}
                alt="arrow down"
                className="w-[80%]  md:flex hidden"
              />
              <img
                src={arrowDownMd}
                alt="arrow down"
                className="w-[90%] m-auto md:hidden flex"
              />
            </div>

            <div className="flex flex-col w-full md:flex-row mobile:w-max">
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
              <div className="flex items-center justify-center mb-4 md:mb-0">
                <img
                  src={arrowLg}
                  alt="arrow next"
                  className="hidden m-auto md:flex"
                />
                <img
                  src={arrowDownMd}
                  alt="arrow next"
                  className="flex m-auto md:hidden"
                />
              </div>
              <SpecialCard
                date={"15 May 2022"}
                title={"Phase 01"}
                desc={"300/1000 Supply"}
              />
            </div>
          </div>
        </div>
      </div>

      {/* our team */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Our Team
          </h1>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 12 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
}

export default BayraMeta;
