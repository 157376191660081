import React from "react";
import heroPattern from "../../images/hero_pattern.png";
import cardCurve2 from "../../images/card-curve-2.svg";
import cardLogo from "../../images/card_logo.svg";

function GamingAboutCard({btnText, func}) {
  return (
    <div>
      <div className="mobile:w-[17rem] w-[94vw]">
        <div className="relative  mobile:w-[17rem] w-[94vw] min-h-[16.8rem] rounded-[3rem] bg-[#0851DF] cursor-pointer">
          <img
            src={cardCurve2}
            alt="cardimage"
            className="absolute right-[-1.4px]  top-[-1.5px]  w-[7rem] z-10 "
          />

          <div className="relative border-[2px]  border-[#0851DF] rounded-[3rem]">
            <img
              src={heroPattern}
              alt="cardimage"
              className="object-cover  rounded-[3rem] h-[16.8rem]"
            />
            <div>
              <button onClick={()=>func()} className="absolute left-0 right-0 bottom-0 mt-4 mb-4 mx-5  rounded-[1.2rem] font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-4 px-5 ">
                {" "}
                {btnText}
              </button>
            </div>
            <div className="absolute top-0 right-0 z-20">
              <div className="w-[3.8rem] h-[3.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
                <img
                  src={cardLogo}
                  alt="cardimage"
                  className="object-center w-[2.2rem] h-[2.2rem]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamingAboutCard;
