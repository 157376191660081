import React from "react";

import ourgamesbg from "../../images/our_games_bg.svg";
import blopsCard from "../../images/our_games_blops_card.svg";
import blopsLogo from "../../images/our_games_blops_logo.svg";
import blopsheroBg from "../../images/our_games-blops_pixel_bg.svg";
import pixelheroCard from "../../images/our_games_pixel_hero_card.png";
import pixelheroLogo from "../../images/our_games_pixel_hero_logo.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import NftCard from "../../components/nft-card";

import companyLogo1 from "../../images/company-logo-1.svg";
import companyLogo2 from "../../images/company-logo-2.svg";
import companyLogo3 from "../../images/company-logo-3.svg";
import companyLogo4 from "../../images/company-logo-4.svg";
import companyLogo5 from "../../images/company-logo-5.svg";
import companyLogo6 from "../../images/company-logo-6.svg";
import HowToPlayCard from "./htplay_card";
import bayraGamingHeroCurve from "../../images/bayra_gaming_hero_curve.svg";
import bayraGamingAboutCurveLg from "../../images/bayra_gaming_about_curve_lg.svg";
import bayraGamingAboutCurveSm from "../../images/bayra_gaming_about_curve_sm.svg";
import GamingAboutCard from "./about_card";

function BayraGamming() {
  return (
    <div>
      {/* bayra hero section */}
      <div className="mt-3 w-[100%] lg:w-[80%] lg:p-0 md:px-8  m-auto flex gap-2 lg:flex-row flex-col">
        {/* bayra gaming */}
        <div className="lg:w-9/12 px-7  m-auto rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242] ">
          <h1 className=" md:ms-[1rem]  mt-9 mb-3 font-PvcBannerUltra text-[20px] mobile:text-[30px] sm:text-[35px] lg:text-[60px] md:text-[44px] font-[600] leading-[60px] text-[#7B7B7B] break-words">
            Bayra Gaming
          </h1>
          <div className=" mb-4 mt-4 md:ms-[1rem] text-[#7B7B7B]">
            <p className="w-[97%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className="w-[97%] leading-[20px] font-[500] text-[12px] mt-4">
              Whether you're looking for a piece to add to your collection or
              want to invest in the future of digital art, our NFT marketplace
              offers a seamless and secure way to buy and sell NFTs. Explore our
              collection today and discover the world of NFTs like never before.
            </p>
            <button className=" mt-4 mb-4  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              {" "}
              Learn More
            </button>
          </div>
        </div>
        {/* blops */}
        <div className="lg:w-[30%] w-[100%] lg:h-auto h-[15rem] relative rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242]">
          <div className="absolute md:flex hidden bottom-[-4px] right-[-4px] ">
            <img
              src={bayraGamingHeroCurve}
              alt="bayragamingherocurve"
              className="bottom-0 right-0"
            />
          </div>
          <div className="absolute h-[6.3rem] md:flex hidden w-[2.7rem] bottom-0 right-0  rounded-[1rem] bg-[#2E2D2E] border-2 border-[#424242]"></div>
          <button className=" absolute left-2 md:rounded-br-none rounded-br-[3rem] rounded-bl-[3rem] bottom-0 lg:w-8/12 md:w-[90.5%] w-[95%] mt-4 mb-2  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
            Play
          </button>
        </div>
        {/* pixel hero */}
        <div className="lg:w-[30%] w-[100%] lg:h-auto h-[15rem] relative rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242]">
          <div className="absolute md:flex hidden bottom-[-4px] right-[-4px] ">
            <img
              src={bayraGamingHeroCurve}
              alt="bayragamingherocurve"
              className="bottom-0 right-0"
            />
          </div>
          <div className="absolute h-[6.3rem] md:flex hidden w-[2.7rem] bottom-0 right-0  rounded-[1rem] bg-[#2E2D2E] border-2 border-[#424242]"></div>
          <button className=" absolute left-2 md:rounded-br-none rounded-br-[3rem] rounded-bl-[3rem] bottom-0 lg:w-8/12 md:w-[90.5%] w-[95%] mt-4 mb-2  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
            Play
          </button>
        </div>
      </div>

      {/* bayra gamming features  */}
      <div className="mt-3 bg-home-features-bg bg-no-repeat bg-center bg-cover md:w-[100%] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="flex flex-col justify-around gap-6 py-5 my-6 align-center sm:flex-row ">
          <div className="flex-col flex-wrap justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Play
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
          <div className="flex flex-col justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Trade
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
          <div className="flex flex-col justify-center align-center">
            <h1 className="font-black italic text-center font-Montserrat  text-[48px] m-0 p-0">
              Earn
            </h1>
            <p className="font-normal text-center text-[20px] mt-[-9px] p-0">
              Anytime
            </p>
          </div>
        </div>
      </div>

      {/* bayra gaming about */}
      <div className="mt-3 pb-4 relative md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6">
          <div className="my-16">
            <div className="flex flex-wrap items-center justify-center gap-3">
              <GamingAboutCard btnText={"About Gameplay"} func={() => {}} />
              <GamingAboutCard btnText={"About Cards"} func={() => {}} />
              <GamingAboutCard btnText={"About Reward"} func={() => {}} />
            </div>
          </div>
          <div className="flex items-end justify-end">
            <div className="mb-4 2xl:w-7/12 xl:w-6/12 xl:ms-5">
              <p className="w-[90%] leading-[20px] font-[500] text-[12px] mt-4 ms-8">
                Step into a world where art meets blockchain technology. Our
                exclusive collection of non-fungible tokens (NFTs) showcases
                some of the most unique and stunning works of digital art from
                talented artists around the world. Each NFT is a one-of-a-kind
                creation, verified on the blockchain, and is sure to capture the
                attention of any collector or art enthusiast.
              </p>
              <p className=" w-[90%] leading-[20px] font-[500] text-[12px] mt-4 ms-8">
                Step into a world where art meets blockchain technology. Our
                exclusive collection of non-fungible tokens (NFTs) showcases
                some of the most unique and stunning works of digital art from
                talented artists around the world. Each NFT is a one-of-a-kind
                creation, verified on the blockchain, and is sure to capture the
                attention of any collector or art enthusiast.
              </p>
              <p className=" w-[90%] leading-[20px] font-[500] text-[12px] mt-4 ms-8">
                Step into a world where art meets blockchain technology. Our
                exclusive collection of non-fungible tokens (NFTs) showcases
                some of the most unique and stunning works of digital art from
                talented artists around the world. Each NFT is a one-of-a-kind
                creation, verified on the blockchain, and is sure to capture the
                attention of any collector or art enthusiast.
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-[-11px] left-[-12px] hidden xl:flex ">
          <img src={bayraGamingAboutCurveLg} alt="bayraGaming about curve" />
        </div>
        <div className="absolute z-10 bottom-0 left-0 hidden w-[30rem] h-[17.6rem] xl:flex bg-[#2E2D2E]   border-2 border-[#424242] rounded-[1.6rem]"></div>
        <div className="absolute bottom-[-6px] left-[-5px] hidden xl:flex z-10">
          <img src={bayraGamingAboutCurveSm} alt="bayraGaming about curve sm" />
        </div>
        <div className="absolute bottom-0 left-0 z-30 hidden w-24 h-24 bg-white rounded-full xl:flex"></div>
      </div>

      <div>
        <div className="relative flex my-4 xl:hidden h-[17.6rem] w-[100%] lg:w-[80%] m-auto bg-[#2E2D2E]   border-2 border-[#424242] rounded-[1.6rem]">
          <div className="absolute bottom-[-6px] left-[-5px] flex xl:hidden z-10">
            <img
              src={bayraGamingAboutCurveSm}
              alt="bayraGaming about curve sm"
            />
          </div>
          <div className="absolute bottom-0 left-0 z-30 flex w-24 h-24 bg-white rounded-full xl:hidden"></div>
        </div>
      </div>

      {/* gamming unleashed */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            GAMING UNLEASHED
          </h1>
          <div className=" mb-4 mt-2 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Other online collectible card games sacrifice the real world value
              of physical cards for the sake of convenience. We see a better
              way.
            </p>
          </div>
          <div>
            <div className="bg-[#BCBCBC] h-14 w-[95%] mb-2"></div>

            <div className="bg-[#BCBCBC] h-52 w-[95%] mb-2 rounded-br-[1.6rem] rounded-bl-[1.6rem]"></div>
          </div>
        </div>
      </div>

      {/* for victory and glory */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            FOR VICTORY AND GLORY
          </h1>
          <div className=" mb-4 mt-2 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Each battle has a different combination of combat rules, total
              Mana cap, and playable Elements! Strategy is critical in creating
              the best team for the given situation.
            </p>
          </div>
        </div>
      </div>

      {/* our games */}
      <div
        style={{
          backgroundImage: `url(${ourgamesbg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white"
      >
        <div className="mt-6 mb-5 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">Our Games</h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] sm:text-[16px] text-[13px]">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world. Each NFT is a one-of-a-kind creation,
            verified on the blockchain, and is sure to capture the attention of
            any collector or art enthusiast.
          </p>
        </div>
        {/* for laptops  */}
        <div className="relative m-auto flex items-initials w-[95%]  flex-col">
          <img
            src={blopsheroBg}
            alt="Background"
            className="hidden object-fill mb-5 xl:flex"
          />
          <div className="absolute   w-[55%] h-[95%] md:p-5 p-0 top-0 left-0 xl:flex hidden items-start justify-between flex-col pt-5 pb-5">
            <div className="    w-[78%]  flex flex-col items-center">
              <img
                src={blopsCard}
                alt="Blops Card"
                className="xl:25rem w-[18rem] object-center object-cover"
              />
            </div>

            <div className="xl:w-[90%] md:w-[100%] w-100">
              <h1 className=" text-[60px] font-[700]">Blops</h1>
              <p className=" xl:text-[14px] md:text-[14px]  font-[500] leading-[20px] text-[#C9C9C9]">
                Blops are a unique and quirky collection of NFTs featuring
                bizarre and odd-looking characters. Each Blops NFT has its own
                set of attributes, including Monsterability, Power, Goofiness,
                and Blopiness. The total of all points determines the rank of a
                Blop. There are four ranks available: Blop, Blop Adv, Blop
                Super, and Blop Ultra, with increasing rarity and attributes as
                the rank increases. With a limited supply of 1000, Blops offer
                collectors a chance to own a rare and distinctive piece of
                digital art.
              </p>
              <button className="py-2 px-14 rounded-md  bg-white text-black text-[16px] mt-3">
                Play Now
              </button>
              <img
                src={blopsLogo}
                alt="Blops Logo"
                className="absolute bottom-[.3rem] right-[.5rem] w-[6rem]"
              />
            </div>
          </div>
          <div className="absolute  w-[55%]  h-[100%]  md:p-5 p-0 top-0 right-0 xl:flex hidden items-stretch justify-between flex-col pt-5 pb-5">
            <div className="relative h-[100%] flex items-start gap-3 ">
              <img
                src={pixelheroLogo}
                alt="Pixel Hero Logo"
                className="w-100 h-100 "
              />

              <div className="flex items-stretch h-[100%] justify-between  flex-col">
                <div>
                  <h1 className=" text-[60px] font-[700] text-black">
                    Pixel Hero
                  </h1>
                  <p className="text-[#38383B] text-[14px]  font-[500] leading-[20px]">
                    Pixel Heroes is a card game NFT project built by an early
                    Proton NFT creator. The project has been handed over to
                    CrypFennecs for integration with the Bayra platform. The
                    game features pixelated characters with various rarities,
                    descriptions, attributes (HP, Armor, Strength, and Magic),
                    and special abilities. The Pixel Heroes collection comprises
                    four types of NFTs: 1st Edition, 2nd Edition, Pixel Avatars
                    (PFPs), and Pixel Heroes Variants. The Pixel Heroes project
                    also has its own token ($PIXEL) that is planned to be
                    replaced by the $BAYRA token on the Bayra platform.
                  </p>
                  <button className="py-2 px-14 rounded-md  bg-[#38383B] text-white text-[16px] mt-3">
                    Play Now
                  </button>
                </div>

                <div className="flex flex-col items-center pb-5">
                  <img
                    src={pixelheroCard}
                    alt="Blops Card"
                    className="xl:25rem w-[18rem] object-center object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles and tablets */}
        <div className="xl:hidden flex flex-col items-center pb-10 mt-3 p-3 w-[100%] bg-black md:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
          <img
            src={blopsCard}
            alt="Blops Card"
            className=" object-cover md:w-[20rem] w-100 items-center object-center"
          />
          <div>
            <div className="flex gap-3">
              <img src={blopsLogo} alt="Blops Logo" className="w-[4rem]" />
              <h1 className=" md:text-[60px] text-[40px]  font-[700]">Blops</h1>
            </div>
            <div className="flex flex-col items-center">
              <p className="  xl:text-[14px] md:text-[14px] text-[12px] font-[500] text-justify leading-[20px] text-[#C9C9C9]">
                Blops are a unique and quirky collection of NFTs featuring
                bizarre and odd-looking characters. Each Blops NFT has its own
                set of attributes, including Monsterability, Power, Goofiness,
                and Blopiness. The total of all points determines the rank of a
                Blop. There are four ranks available: Blop, Blop Adv, Blop
                Super, and Blop Ultra, with increasing rarity and attributes as
                the rank increases. With a limited supply of 1000, Blops offer
                collectors a chance to own a rare and distinctive piece of
                digital art.
              </p>
              <button className="py-2 px-14 rounded-md  bg-white text-black text-[16px] mt-3 m-auto">
                Play Now
              </button>
            </div>
          </div>
        </div>
        <div className="xl:hidden flex flex-col text-black items-center pb-10 mt-3 mb-4 p-3 w-[100%] bg-[#A8A6A1] md:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
          <img
            src={pixelheroCard}
            alt="pixelheroCard"
            className=" object-cover md:w-[20rem] w-100 items-center object-center"
          />
          <div>
            <div className="flex gap-3">
              <img
                src={pixelheroLogo}
                alt="pixelheroLogo"
                className="w-[4rem]"
              />
              <h1 className=" md:text-[60px] text-[40px] font-[700]">
                Pixel Hero
              </h1>
            </div>
            <div className="flex flex-col items-center">
              <p className=" xl:text-[14px] md:text-[14px] text-[12px] text-justify  font-[500] leading-[20px] text-[#38383B]">
                Pixel Heroes is a card game NFT project built by an early Proton
                NFT creator. The project has been handed over to CrypFennecs for
                integration with the Bayra platform. The game features pixelated
                characters with various rarities, descriptions, attributes (HP,
                Armor, Strength, and Magic), and special abilities. The Pixel
                Heroes collection comprises four types of NFTs: 1st Edition, 2nd
                Edition, Pixel Avatars (PFPs), and Pixel Heroes Variants. The
                Pixel Heroes project also has its own token ($PIXEL) that is
                planned to be replaced by the $BAYRA token on the Bayra
                platform.
              </p>
              <button className="py-2 px-14 rounded-md  bg-[#38383B] text-white text-[16px] mt-3">
                Play Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* powered by blockchain */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Powered by blockChain
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* blops nft’s */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Blops NFT’s</h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
        </div>

        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
          </div>
        </ScrollContainer>
      </div>

      {/* pixel hero nft's */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Pixel Hero NFT’s</h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 overflow-auto no-scrollbar "
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
          </div>
        </ScrollContainer>
      </div>

      {/* play to earn */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Play To Earn
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* how to play */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            How to Play?
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
          <div className="w-[95%] flex gap-3 flex-wrap items-center justify-center mt-3 mb-2">
            <HowToPlayCard
              title={"4 Rarities"}
              desc={
                "There are four Rarity types: Common, Rare, Epic, and Legendary. Each has different level and circulation caps."
              }
            />
            <HowToPlayCard
              title={"4 Rarities"}
              desc={
                "There are four Rarity types: Common, Rare, Epic, and Legendary. Each has different level and circulation caps."
              }
            />
            <HowToPlayCard
              title={"4 Rarities"}
              desc={
                "There are four Rarity types: Common, Rare, Epic, and Legendary. Each has different level and circulation caps."
              }
            />
            <HowToPlayCard
              title={"4 Rarities"}
              desc={
                "There are four Rarity types: Common, Rare, Epic, and Legendary. Each has different level and circulation caps."
              }
            />
            <HowToPlayCard
              title={"4 Rarities"}
              desc={
                "There are four Rarity types: Common, Rare, Epic, and Legendary. Each has different level and circulation caps."
              }
            />
          </div>
          <button className="w-[95%] py-3 mt-3 w-100 rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white  px-5 ">
            Play Now
          </button>
        </div>
      </div>

      {/* got crypto? */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Got Crypto?
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>

      {/* company section */}
      <div className="mt-3 md:w-[100%] bg-black lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#0851DF] text-white">
        <div className="flex flex-col items-center pt-10 pb-10">
          <h1 className=" font-[700] text-[34px] my-10 text-center">
            A WORLDWIDE REVOLUTION IN GAMING
          </h1>
          <div className="flex flex-wrap items-center justify-center gap-10 mb-7">
            <img src={companyLogo1} alt="Company logo 1"></img>
            <img src={companyLogo2} alt="Company logo 2"></img>
            <img src={companyLogo3} alt="Company logo 3"></img>
            <img src={companyLogo4} alt="Company logo 4"></img>
            <img src={companyLogo5} alt="Company logo 5"></img>
            <img src={companyLogo6} alt="Company logo 6"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BayraGamming;
