import React from "react";

function HowToPlayCard({title, desc}) {
  return (
    <div className="px-3 mobile:p-0">
      <div className="p-3 mobile:w-[13rem] w-[90%] bg-[#9D9D9D] rounded-[1.6rem]">
        <h1 className="text-[18px] font-[600] text-[#5A5A5A] ps-2 pb-2">
          {title}
        </h1>
        <p className="text-[12px] font-[500] text-[#D9D9D9] leading-[13px]">
          {desc}
        </p>
      </div>
    </div>
  );
}

export default HowToPlayCard;
