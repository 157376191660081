import React, { useState } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.svg";
import "../styles/Navbar.css";

const Navbar = () => {
  const [icon, seticon] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const location = useLocation();

  return (
    <div className="flex bg-[#2E2D2E] justify-between py-4 md:w-[100%] lg:w-[80%] m-auto rounded-bl-[3rem] rounded-br-[3rem] px-7 border-2 border-[#424242]    items-center  text-white ">
      <div className="auto lg:block flex-nowrap">
        <img src={logo} className="object-cover h-100 w-100" alt="Logo" />
      </div>

      <div className="flex items-center justify-end w-full flex-wrap  lg:w-[70%]">
        <ul
          className={icon ? "menu active  gap-6" : "menu lg:gap-8 gap-6"}
          onClick={() => seticon(!icon)}
        >
          <li className="menuitem">
            <Link
              to="/"
              active
              onClick={() => {
                seticon(!icon);
                setActiveLink("home");
              }}
              className={
                location.pathname == "/"
                  ? "active_link"
                  : "text-white"
              }
            >
              Home
            </Link>
          </li>
          <li className="menuitem">
            <Link
              to="/discover"
              onClick={() => {
                seticon(!icon);
                setActiveLink("discover");
              }}
              className={
                location.pathname.includes("discover")
                  ? "active_link"
                  : "text-white"
              }
            >
              Discover
            </Link>
          </li>
          <li className="menuitem">
            <Link
              to="/marketplace"
              onClick={() => {
                seticon(!icon);
                setActiveLink("marketplace");
              }}
              className={
                location.pathname.includes("marketplace")
                  ? "active_link"
                  : "text-white"
              }
            >
              MarketPlace
            </Link>
          </li>
          <li className="menuitem">
            <Link
              to="/bayragaming"
              onClick={() => {
                seticon(!icon);
                setActiveLink("gaming");
              }}
              className={
                location.pathname.includes("gaming")
                  ? "active_link"
                  : "text-white"
              }
            >
              Gaming
            </Link>
          </li>

          <li className="menuitem">
            <Link
              to="/aboutus"
              onClick={() => {
                seticon(!icon);
                setActiveLink("aboutus");
              }}
              className={
                location.pathname.includes("aboutus")
                  ? "active_link"
                  : "text-white"
              }
            >
              About Us
            </Link>
          </li>
          <button
            type="button"
            className="w-24 lg:w-[170.48px]  h-8 bg-[#2B55FF] rounded-[6px] text-white text-sm"
          >
            Log in
          </button>
        </ul>
      </div>

      <div className="menu__icon lg:hidden">
        <span
          className="text-white navbar__icon"
          onClick={() => seticon(!icon)}
        >
          {!icon ? (
            <FaBars className="text-white" />
          ) : (
            <FaTimes className="text-white" />
          )}
        </span>
      </div>
    </div>
  );
};

export default Navbar;
