import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ChapterCard from "../../components/chapter-card";
import chapterData from "./data";
import FennceCard from "../../components/fennce-card";

function Chapters() {
  const [data, setData] = useState(chapterData);

  const [selectedChapter, setSelectedChapter] = useState(0);
  console.log(selectedChapter);
  return (
    <div>
      {/* the chapters */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            The Chapters
          </h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {ChapterCard({
              heroPattern: require("../../images/chapter1.jpg"),
              chapter: "Chapter 1",
              title: "Origins",
              isSelected: selectedChapter === 0 ? true : false,
              func: () => {
                setSelectedChapter(0);
              },
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter2.jpg"),
              chapter: "Chapter 2",
              title: "The Rise of the Fennecs",
              isSelected: selectedChapter === 1 ? true : false,
              func: () => {
                setSelectedChapter(1);
              },
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter3.png"),
              chapter: "Chapter 3",
              title: "The Fennce Wars",
              isSelected: selectedChapter === 2 ? true : false,
              func: () => {
                setSelectedChapter(2);
              },
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter4.png"),
              chapter: "Chapter 4",
              title: "Apocalyptics",
              isSelected: selectedChapter === 3 ? true : false,
              func: () => {
                setSelectedChapter(3);
              },
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter5.jpg"),
              chapter: "Chapter 5",
              title: "Civilization Peak",
              isSelected: selectedChapter === 4 ? true : false,
              func: () => {
                setSelectedChapter(4);
              },
            })}
            {ChapterCard({
              heroPattern: require("../../images/chapter6.png"),
              chapter: "Chapter 6",
              title: "Galactic",
              isSelected: selectedChapter === 5 ? true : false,
              func: () => {
                setSelectedChapter(5);
              },
            })}
          </div>
        </ScrollContainer>
      </div>

      {/* chapter details */}
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(32, 32, 32, 0.7), rgba(32, 32, 32, 0.7)), url(${data[selectedChapter].image})`,
        }}
        className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white bg-right-top bg-cover bg-no-repeat"
      >
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[400] mobile:text-[20px] text-[14px] text-white  font-PvcBanner mt-9">
            {data[selectedChapter].chapter}
          </h1>
          <h1 className="md:w-[60%] font-[700] sm:text-[48px] mobile:text-[28px] text-[19px] text-white mb-2 font-PvcBannerUltra">
            {data[selectedChapter].title}
          </h1>
          <p className="text-white md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
            {data[selectedChapter].description[0]}
          </p>
          <p className="text-white md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
            {data[selectedChapter].description[1]}
          </p>
          <p className="text-white md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
            {data[selectedChapter].description[2]}
          </p>
        </div>
      </div>

      {/* the fennecs */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            The Fennecs
          </h1>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 12 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>

      {/* the fennecs */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="flex flex-col items-center gap-5 my-12 md:ms-14 md:flex-row md:items-end">
          <div className="lg:w-[30%] md:w-[40%] w-[90%] lg:h-[30rem] md:h-[30rem] h-[20rem] bg-[#9D9D9D]  rounded-xl">
            
          </div>
          <div className="lg:w-[70%] md:w-[60%] w-[90%]">
            <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
              The Fennecs
            </h1>
            <p className="text-[#7B7B7B] md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className="text-[#7B7B7B] md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className="text-[#7B7B7B] md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4 mb-5">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chapters;
