import React from "react";
import FennceCard from "../../components/fennce-card";
import ScrollContainer from "react-indiana-drag-scroll";

function MarketplacePixelHero() {
  return (
    <div>
      {/*  hero section */}
      <div className="mt-3 w-[100%] lg:w-[80%]  m-auto flex gap-2 lg:flex-row flex-col">
        <div className="px-7  m-auto rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242] ">
        <h1 className="break-all md:ms-[1rem]  mt-9 mb-3 font-PvcBannerUltra text-[20px] mobile:text-[18px] sm:text-[26px] lg:text-[60px] md:text-[44px] font-[600] lg:leading-[80px] md:leading-[60px] leading-[50px]  text-[#7B7B7B] break-words">
             Pixel Hero
          </h1>
          <div className=" mb-4 mt-4 md:ms-[1rem] text-[#7B7B7B]">
            <p className="w-[97%] leading-[20px] font-[500] text-[12px] mt-4">
              Crypfennecs tells the story of 938 Unique Fennec Characters living
              under the Rule of "The One Saviour". Rarity System: "The One"{" "}
              {">"} Rare Chiefs {"("}
              {"<"}5%{")"} {">"} Uncommons {">"} Commons Specials {">"} Commons.
              We are Exclusive on Proton and are not affiliated with any Other
              Platform. The Fennec Verse consists of different chapters &
              Timelines. The Chapters Released so far are: Fennec Origins, The
              Rise of The Fennec's( Gladiators, The Old West, and others), The
              Fennec Wars, Aplocalyptic Series( Undead and Others), Civilization
              Peak and Galactic. You can read the full story-line & discover the
              Fennec World, Project Utility, Road Map, DAO, Community & Our
              Private Club Benefits On Bayra.io
            </p>

            <button className=" mt-4 mb-4  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* crypfennec */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
        <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
             Pixel Hero
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <div className="flex flex-row flex-wrap gap-3 mt-4 mb-4">
              <div>
                <select
                  id="countries"
                  className="  w-44 rounded-md  font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wide  text-white py-2 px-5 "
                >
                  <option selected>Available</option>
                </select>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Lowest Offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Highest offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Latest Release
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Earliest Release
                </p>
              </div>
            </div>
          </div>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 24 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
}

export default MarketplacePixelHero;
