import React from "react";
import NftCard from "../../components/nft-card";
import ScrollContainer from "react-indiana-drag-scroll";
import CrypUtilityCard from "../../components/cryp-utility-card";

import cardLogo4 from "../../images/cardLogo4.svg";
import cardLogo5 from "../../images/cardLogo5.svg";
import cardLogo6 from "../../images/cardLogo6.svg";

import heroStory from "../../images/hero_story.svg";
import heroUtility from "../../images/hero_utility.svg";
import heroDAO from "../../images/hero_dao.svg";

function Crypfennec() {
  return (
    <div>
      {/* hero section */}
      <div>
        <div className="mt-3 md:w-[100%] lg:w-[80%] m-auto px-7   bg-[#2E2D2E]   bg-top relative z-20 rounded-[3rem] border-2 border-[#424242] bg-no-repeat bg-cover">
          
          <h1 className=" md:ms-[1rem]  mt-3 font-PvcBannerUltra  mobile:text-[25px] sm:text-[35px] lg:text-[60px] md:text-[44px] text-white break-words">Crypfennec.</h1>
          
          <div className=" mb-4 mt-4 md:ms-[1rem] ">
            <p className=" md:w-6/6 lg:w-5/6 text-[13px] lg:text-[16px] md:text-[14px] text-[#7B7B7B] font-600 ">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
          <div className=" mb-8 md:ms-[1rem] ">
            <p className=" md:w-2/6 lg:w-5/12 text-[13px] lg:text-[16px] md:text-[14px] text-[#7B7B7B] font-600 ">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
          <div className="absolute bottom-[-1.7px] right-[-1.8px] hidden md:flex gap-3 pt-3 pl-3 bg-black rounded-tl-[3rem]   border-t-2 border-l-2 border-[#424242]">
            <img src={heroStory} alt="heroBayra" />
            <img src={heroUtility} alt="heroCypho" />
            <img src={heroDAO} alt="heroGame" />
          </div>
        </div>
        <div className=" mt-3 right-[-1.8px] flex  align-top justify-around md:hidden gap-3 p-3 bg-black rounded-tl-[3rem] rounded-tr-xl rounded-br-xl rounded-bl-xl  border-2 border-[#424242]">
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroStory} alt="heroBayra" />
          </div>
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroUtility} alt="heroCypho" />
          </div>
          <div className="flex flex-col col-4 w-100 h-100">
            <img src={heroDAO} alt="heroGame" />
          </div>
        </div>
      </div>

      {/* the story */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-white mb-2">The Story</h1>
          <p className="text-white w-[90%] leading-[20px] font-[500] sm:text-[16px] text-[12px] mt-2">
            For years, the fox fennecs faced extinction due to the selfish
            actions of human’s.Seeing the state of the foxes, the Leader Fennec
            later to be called “The Savior” ☀️ decided it was time to act or
            inevitable doom was imminent. He gathered all his Commanders, the
            chiefs, and his scientists. A meeting was held and the outcome was
            to change the destiny of Foxes forever. The scientists created ”The
            Formula” that can transform fennec's to super Foxes. Contaminating
            the water supply with the formula, the fennec’s transformed, and a
            new era begun with a much more superior civilization of fennec’s.
          </p>
          <p className="text-white w-[90%] leading-[20px] font-[500] sm:text-[16px] text-[12px] mt-3">
            This universe contains over 500-TBD Foxes:
            <ol className="list-decimal ps-6">
              <li>Legendary Leader Fox,</li>
              <li>Rare Chiefs</li>
              <li>Uncommon Commanders & Scientists 👨‍🔬 ,</li>
              <li>Common Civilian Foxes & Soldier Foxes, & </li>
              <li>Common Specials🧛‍️( Vampires, Zombies et</li>
            </ol>
          </p>
          <button className=" mt-3 rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
            {" "}
            Learn More
          </button>
        </div>
      </div>

      {/* the interactive map */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            The Interactive Map
          </h1>
        </div>
      </div>

      {/* home Crypfennec */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Utility
          </h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px] mt-2">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world. Each NFT is a one-of-a-kind creation,
            verified on the blockchain, and is sure to capture the attention of
            any collector or art enthusiast.
          </p>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px] mt-1">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world.
          </p>
        </div>
        <div className="flex flex-col justify-center mt-5 mb-5 items-center  md:flex-row md:w-[80%] w-[100%] m-auto gap-4 md:p-0 p-4">
          {CrypUtilityCard({
            image: require("../../images/crypfennec_story.jpeg"),
            logo: cardLogo6,
          })}
          {CrypUtilityCard({
            image: require("../../images/crypfennec_utility.jpeg"),
            logo: cardLogo4,
          })}
          {CrypUtilityCard({
            image: require("../../images/crypfennec_dao.jpeg"),
            logo: cardLogo5,
          })}
        </div>
      </div>

      {/* dao */}
      <div className="mt-3 pb-4 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 sm:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">DAO</h1>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px] mt-2">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world. Each NFT is a one-of-a-kind creation,
            verified on the blockchain, and is sure to capture the attention of
            any collector or art enthusiast.
          </p>
          <p className="text-[#7B7B7B] w-[90%] leading-[20px] font-[500] text-[12px] mt-1">
            Step into a world where art meets blockchain technology. Our
            exclusive collection of non-fungible tokens (NFTs) showcases some of
            the most unique and stunning works of digital art from talented
            artists around the world.
          </p>
        </div>
      </div>

      {/* recently added */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Added</h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
          </div>
        </ScrollContainer>
      </div>

      {/* recently auctions */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 ms-14">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Auctions</h1>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 overflow-auto no-scrollbar "
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
}

export default Crypfennec;
