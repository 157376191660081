import React from "react";

import cardCurve2 from "../images/card-curve-2.svg";
import cardCurve2Blank from "../images/card-curve-2-blank.svg";

function ChapterCard({ heroPattern, chapter, title, isSelected,func}) {
  return (
    <div className="w-[10.9rem] ">
      <div className={`relative w-[10.9rem]  min-h-[12.5rem] rounded-[1.6rem] ${isSelected?'bg-[#0851DF]': ''} cursor-pointer`}>
        {
          isSelected === true?
        
          <img
            src={cardCurve2}
            alt="cardimage"
            className="absolute right-[-1.4px] top-[-8px] h-[6rem]  w-[5rem] z-30"
          />:
          <img
            src={cardCurve2Blank}
            alt="cardimage"
            className="absolute right-[-1.4px] top-[-8px] h-[6rem]  w-[5rem] z-30"
          />
        }
        

        <div onClick={()=>func()} className={`relative   ${isSelected===true?'border-[2px] border-[#0851DF]': ''} rounded-[1.6rem]`}>
          <img
            src={heroPattern}
            alt="cardimage"
            className=" object-cover object-right-bottom rounded-[1.6rem] h-[12.5rem] "
          />
          <div className="absolute top-0 left-0 w-[10.7rem] min-h-[12.5rem] bg-gradient-to-b rounded-[1.6rem] from-gray-900 via-transparent"></div>
          <div className="absolute left-3 top-4">
            <p className="text-[13px] font-normal">{chapter}</p>
            <h1 className="text-[15px]  font-bold w-3/4">{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChapterCard;
