import cardCurve2 from "../../images/curve_blue_black.svg";

function StatsCard({ image }) {
  return (
    <div className="relative w-[18.979rem]">
      <img
        src={cardCurve2}
        alt="curve"
        className="absolute right-[-1.4px]  top-[-2px]  w-[5.5rem] z-10 "
      />
     
      <div className=" h-[16rem] w-[18.979rem]  relative border-[1.9px] border-[#0851DF] rounded-[1.5rem]">
        <img
          src={image}
          alt="cardimage"
          className="object-cover object-center w-[18.979rem]  h-[15.8rem] rounded-[1.5rem]"
        />

        <div className="absolute top-0 right-0 z-20">
          <div className="w-[2.8rem] h-[2.8rem] rounded-full bg-[#0851DF] flex items-center justify-center">
            {/* <img
              src={logo}
              alt="logo"
              className="object-center w-[2.2rem] h-[2.2rem]"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCard;
