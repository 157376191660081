import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import NftCard from "../../components/nft-card";
import FennceCard from "../../components/fennce-card";

function MarketPlace() {
  return (
    <div>
      {/*  hero section */}
      <div className="mt-3 w-[100%] lg:w-[80%] lg:p-0 md:px-8  m-auto flex gap-2 lg:flex-row flex-col">
        {/* bayra gaming */}
        <div className="lg:w-9/12 px-7  m-auto rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242] ">
          <h1 className=" md:ms-[1rem]  mt-9 mb-3 font-PvcBannerUltra text-[20px] mobile:text-[30px] sm:text-[35px] lg:text-[60px] md:text-[44px] font-[600] leading-[60px] text-[#7B7B7B] break-words">
            Market<br></br>Place
          </h1>
          <div className=" mb-4 mt-4 md:ms-[1rem] text-[#7B7B7B]">
            <p className="w-[97%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <p className="w-[97%] leading-[20px] font-[500] text-[12px] mt-4">
              Whether you're looking for a piece to add to your collection or
              want to invest in the future of digital art, our NFT marketplace
              offers a seamless and secure way to buy and sell NFTs. Explore our
              collection today and discover the world of NFTs like never before.
            </p>
            <button className=" mt-4 mb-4  rounded-md font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wider text-white py-2 px-5 ">
              Learn More
            </button>
          </div>
        </div>
        {/* crypfennec */}
        <div className="lg:w-[20%] w-[100%] lg:h-auto h-[15rem] relative rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242]"></div>
        {/* blops */}
        <div className="lg:w-[20%] w-[100%] lg:h-auto h-[15rem] relative rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242]"></div>
        {/* pixel hero */}
        <div className="lg:w-[20%] w-[100%] lg:h-auto h-[15rem] relative rounded-[3rem] bg-[#2E2D2E] border-2 border-[#424242]"></div>
      </div>

      {/* recently mints */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Mints</h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
        </div>

        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 scrolling-touch no-scrollbar"
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
            {NftCard({ isSubmit: false })}
          </div>
        </ScrollContainer>
      </div>

      {/* pixel hero nft's */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">Recently Auctions</h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
          </div>
        </div>
        <ScrollContainer
          horizontal={true}
          className=" scroll-container w-[95%] m-auto mt-5 mb-5 overflow-auto no-scrollbar "
        >
          <div className="flex items-start gap-4 p">
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
            {NftCard({ isSubmit: true })}
          </div>
        </ScrollContainer>
      </div>

      {/* our nft stats */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] mb-2">Our Nft Stats</h1>
          <div className=" w-[95%] mb-9 flex md:flex-row flex-col gap-2 items-center justify-center">
            <div className="md:w-[32%] w-[95%] h-52 bg-slate-300 rounded-[1.6rem]"></div>
            <div className="md:w-[32%] w-[95%] h-52 bg-slate-300 rounded-[1.6rem] "></div>
            <div className="md:w-[32%] w-[95%] h-52 bg-slate-300 rounded-[1.6rem]"></div>
          </div>
        </div>
      </div>

      {/* crypfennec */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Crypfennec
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <div className="flex flex-row flex-wrap gap-3 mt-4 mb-4">
              <div>
                <select
                  id="countries"
                  className="  w-44 rounded-md  font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wide  text-white py-2 px-5 "
                >
                  <option selected>Available</option>
                </select>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Lowest Offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Highest offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Latest Release
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Earliest Release
                </p>
              </div>
            </div>
          </div>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>

      {/* blops */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Blops
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <div className="flex flex-row flex-wrap gap-3 mt-4 mb-4">
              <div>
                <select
                  id="countries"
                  className="  w-44 rounded-md  font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wide  text-white py-2 px-5 "
                >
                  <option selected>Available</option>
                </select>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Lowest Offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Highest offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Latest Release
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Earliest Release
                </p>
              </div>
            </div>
          </div>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>

        {/* pixel hero */}
      <div className="mt-3 md:w-[100%] bg-[#2E2D2E] lg:w-[80%] m-auto rounded-[3rem] border-2 border-[#424242] text-white">
        <div className="mt-6 md:ms-14 ms-8">
          <h1 className=" font-[700] text-[34px] text-[#5A5A5A] mb-2">
            Pixel Hero
          </h1>
          <div className=" mb-4 mt-4 text-[#7B7B7B] ">
            <p className=" md:w-[85%] w-[90%] leading-[20px] font-[500] text-[12px] mt-4">
              Step into a world where art meets blockchain technology. Our
              exclusive collection of non-fungible tokens (NFTs) showcases some
              of the most unique and stunning works of digital art from talented
              artists around the world. Each NFT is a one-of-a-kind creation,
              verified on the blockchain, and is sure to capture the attention
              of any collector or art enthusiast.
            </p>
            <div className="flex flex-row flex-wrap gap-3 mt-4 mb-4">
              <div>
                <select
                  id="countries"
                  className="  w-44 rounded-md  font-Monument text-[15px] font-normal bg-[#BF08DE] tracking-wide  text-white py-2 px-5 "
                >
                  <option selected>Available</option>
                </select>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Lowest Offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Highest offer
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Latest Release
                </p>
              </div>
              <div className="w-44 rounded-[1.6rem]    bg-[#BF08DE] tracking-wide  text-white py-2 px-5 ">
                <p className="text-[15px] font-[500] text-center">
                  Earliest Release
                </p>
              </div>
            </div>
          </div>
        </div>
        <ScrollContainer
          horizontal={false}
          vertical={true}
          className="w-[95%] m-auto mt-5 mb-5 "
        >
          <div className="flex flex-wrap items-start justify-center gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <FennceCard
                key={index}
                heroPattern={require("../../images/chapter1.jpg")}
                name="Lorem Ipsum"
                owner="Lorem Ipsum"
                nftCount="850000"
                func={() => {}}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
      
    </div>
  );
}

export default MarketPlace;
